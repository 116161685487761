import "./assets/css/normalize.css";
import "./assets/css/vars.css";
import "./assets/css/style.css";
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import noRightClick from './directives/no-right-click';
import { languageStore } from './stores/languageStore';

const app = createApp(App);

const storedLanguage = localStorage.getItem('language');
if (storedLanguage) {
	languageStore.setLang(storedLanguage);
}

app.directive('no-right-click', noRightClick);
app.use(router);
app.mount('#app');

document.addEventListener("touchstart", function (event) {
	if (event.touches.length > 1) {
		event.preventDefault();
	}
}, { passive: false });

document.addEventListener("touchend", function (event) {
	let now = Date.now();
	if (now - (window.lastTouchEnd || 0) <= 300) {
		event.preventDefault();
	}
	window.lastTouchEnd = now;
}, false);

document.addEventListener("gesturestart", function (event) {
	event.preventDefault();
});

document.addEventListener("gesturechange", function (event) {
	event.preventDefault();
});
